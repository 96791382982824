import { Vue, Component } from "vue-property-decorator";
import Spinner from "@/store/modules/spinnerModule";
import Snackbar from "@/store/modules/errorSnackbar";
import JsonExcel from "vue-json-excel";
import { TableHeader } from "@/models/vuetifyModels";
import reporte from "@/components/reporte.vue";
import { ApiSistemaPagos, ApiErrorResponse, AxiosHttpClient } from "apd.apiconnectors";
import { getUrl, ApiList } from "@/config/apiUrls";
import { Kiosko, Filtro, ResumenFormaPago } from "apd.models";
import Dropdowns from "@/store/modules/dropdownModule";
import cajerosForm from "@/components/forms/cajerosForm.vue";
import cajerosTable from "@/components/tables/cajerosTable.vue";
import { mdiClose } from "@mdi/js";
import { Rules } from "@/models/rules";

Vue.component("DownloadExcel", JsonExcel);

@Component({
  components: {
    reporte,
    cajerosForm,
    cajerosTable,
  },
  filters: {
    toMoney(value: number): string {
      if (typeof value !== "number") {
        return value;
      }
      return "$" + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
  },
})
export default class ReporteCajerosComponent extends Vue {
  public date = "";
  public date2 = "";
  public IconClose: string = mdiClose;
  public dialog = false;
  public cajerosArr = [] as Kiosko[];
  public clienteSP = (): ApiSistemaPagos => {
    const client = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.$store.getters.appMode));
    client.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
    return new ApiSistemaPagos(client);
  };

  public json_meta = [
    [
      {
        key: "charset",
        value: "utf-8",
      },
    ],
  ];
  public json_fields = {
    Cajero: "medio",
    PagosTotal: "cantidadOperaciones",
    ImporteTotal: {
      field: "totalImporte",
      callback: (value: number): string => {
        return "$" + value.toFixed(2);
      },
    },
    EfectivoTotal: {
      field: "totalEfectivo",
      callback: (value: number): string => {
        return "$" + value.toFixed(2);
      },
    },
    TotalTarjeta: {
      field: "totalTarjeta",
      callback: (value: number): string => {
        return "$" + value.toFixed(2);
      },
    },
    TotalCheques: {
      field: "totalCheques",
      callback: (value: number): string => {
        return "$" + value.toFixed(2);
      },
    },
    TotalCoDi: {
      field: "totalCoDi",
      callback: (value: number): string => {
        return "$" + value.toFixed(2);
      },
    },
    Comisiones: {
      field: "totalComisiones",
      callback: (value: number): string => {
        return "$" + value.toFixed(2);
      },
    },
  };
  public headers: TableHeader[] = [
    {
      text: "Cajero",
      value: "medio",
      align: "center",
      sortable: true,
    },
    {
      text: "Pagos Total",
      value: "totalPagos",
      align: "center",
      sortable: false,
    },
    {
      text: "Total Importe",
      value: "totalImporte",
      align: "center",
      sortable: false,
      width: "15%",
    },
    {
      text: "Efectivo",
      value: "totalEfectivo",
      align: "center",
      sortable: false,
      width: "15%",
    },
    {
      text: "Tarjeta",
      value: "totalTarjeta",
      align: "center",
      sortable: false,
      width: "15%",
    },
    {
      text: "Cheques",
      value: "totalCuentaCheques",
      align: "center",
      sortable: false,
      width: "15%",
    },
    {
      text: "Cheques",
      value: "totalCuentaCheques",
      align: "center",
      sortable: false,
      width: "15%",
    },
    {
      text: "Comisiones",
      value: "comisiones",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];

  public formDataVista: ResumenFormaPago[] = [];

  public get loading(): boolean {
    return Spinner.showLoader;
  }

  public get rules(): Rules {
    return this.$store.state.validationRules;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public agregarCajerosSinOperaciones(data: ResumenFormaPago[]) {
    const nombreCajeros = [] as string[];
    this.cajerosArr.forEach((o) => {
      nombreCajeros.push(o.nombreReportes);
    });
    nombreCajeros.forEach((o) => {
      if (data.find((i) => i.medio == o) == undefined) {
        const sinPagos: ResumenFormaPago = {
          servicio: "",
          medio: o,
          cantidadOperaciones: 0,
          totalImporte: 0,
          cantidadEfectivo: 0,
          totalEfectivo: 0,
          cantidadTarjeta: 0,
          totalTarjeta: 0,
          cantidadCheques: 0,
          totalCheques: 0,
          cantidadCoDi: 0,
          totalCoDi: 0,
          cantidadComisiones: 0,
          totalComisiones: 0,
          totalComisionesTarjeta: 0,
          totalComisionesOtros: 0,
          totalImpuestoComisiones: 0,
          totalImpuestoComisionesTarjeta: 0,
          totalImpuestoComisionesOtros: 0,
        };
        data.push(sinPagos);
      }
    });
    return data;
  }

  public agregarCajerosSinOperacionesFiltroCajeros(data: ResumenFormaPago[], kioskos: string[]): ResumenFormaPago[] {
    const nombreCajeros = [] as string[];
    kioskos.forEach((o) => {
      const cajero = this.cajerosArr.find((kiosko) => kiosko.id == o);
      if (cajero) nombreCajeros.push(cajero.nombreReportes);
    });
    nombreCajeros.forEach((o) => {
      if (data.find((i) => i.medio == o) == undefined) {
        const sinPagos: ResumenFormaPago = {
          servicio: "",
          medio: o,
          cantidadOperaciones: 0,
          totalImporte: 0,
          cantidadEfectivo: 0,
          totalEfectivo: 0,
          cantidadTarjeta: 0,
          totalTarjeta: 0,
          cantidadCheques: 0,
          totalCheques: 0,
          cantidadCoDi: 0,
          totalCoDi: 0,
          cantidadComisiones: 0,
          totalComisiones: 0,
          totalComisionesTarjeta: 0,
          totalComisionesOtros: 0,
          totalImpuestoComisiones: 0,
          totalImpuestoComisionesTarjeta: 0,
          totalImpuestoComisionesOtros: 0,
        };
        data.push(sinPagos);
      }
    });
    return data;
  }

  public async mounted(): Promise<void> {
    this.cajeros();
  }

  public async cajeros(): Promise<void> {
    //if ((Dropdowns.ListaKioskos() as Kiosko[]).length == 0) {
    Dropdowns.GetKioskos()
      .then(() => {
        this.cajerosArr = Dropdowns.ListaKioskos() as Kiosko[];
        this.cajerosArr = this.cajerosArr.filter(
          (o) =>
            o.mostrarReportes ||
            (o.ubicacion == "WEB" && !o.nombreReportes.startsWith("Caj") && !o.nombreReportes.endsWith("Ext")),
        );
        this.cajerosArr.sort((a, b) => a.nombreReportes.localeCompare(b.nombreReportes));
      })
      .catch((resp) => {
        const err: ApiErrorResponse = resp as ApiErrorResponse;
        Snackbar.Show(err.apiError.mensajeUsuario);
        Spinner.Hide();
      });
  }

  public async buscar(formFiltro: Filtro): Promise<void> {
    Spinner.Show();
    if (formFiltro.fechaInicial) {
      this.date = formFiltro.fechaInicial;
    }

    if (formFiltro.fechaFinal) {
      this.date2 = formFiltro.fechaFinal;
    }
    //Este if y su contenido es una mexicanada borrar despues de actualizar el API de Sistema Pagos a una version mas nueva de .net
    if (formFiltro.kioskos?.length == 1) {
      formFiltro.kioskos?.push(formFiltro.kioskos[0]);
    }
    await this.clienteSP()
      .GetResumenCajeros(formFiltro)
      .then((resp) => {
        //Este if y su contenido es una mexicanada borrar despues de actualizar el API de Sistema Pagos a una version mas nueva de .net
        if (formFiltro.kioskos?.length == 2) {
          if (formFiltro.kioskos[0] === formFiltro.kioskos[1]) {
            formFiltro.kioskos?.pop();
          }
        }

        if (formFiltro.kioskos?.length != this.cajerosArr.length && formFiltro.kioskos?.length != 0) {
          if (formFiltro.kioskos)
            this.formDataVista = this.agregarCajerosSinOperacionesFiltroCajeros(
              resp as unknown as ResumenFormaPago[],
              formFiltro.kioskos,
            );
          else this.formDataVista = resp as unknown as ResumenFormaPago[];
        } else {
          this.formDataVista = this.agregarCajerosSinOperaciones(
            resp as unknown as ResumenFormaPago[],
          ) as ResumenFormaPago[];
        }
        this.dialog = true;
        Spinner.Hide();
      })
      .catch((resp) => {
        //Este if y su contenido es una mexicanada borrar despues de actualizar el API de Sistema Pagos a una version mas nueva de .net
        if (formFiltro.kioskos?.length == 2) {
          if (formFiltro.kioskos[0] === formFiltro.kioskos[1]) {
            formFiltro.kioskos?.pop();
          }
        }
        const err: ApiErrorResponse = resp as ApiErrorResponse;
        this.formDataVista = [];
        Snackbar.Show(err.apiError.mensajeUsuario);
        Spinner.Hide();
      });
  }
}
