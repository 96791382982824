import { Vue, Component } from "vue-property-decorator";
import JsonExcel from "vue-json-excel";
import reporte from "@/components/reporte.vue";
import { ApiSistemaPagos, AxiosHttpClient } from "apd.apiconnectors";
import { getUrl, ApiList } from "@/config/apiUrls";
import { Kiosko, ResumenFormaPago } from "apd.models";
import { mdiClose, mdiCalendarExport } from "@mdi/js";
import { Rules } from "@/models/rules";

Vue.component("DownloadExcel", JsonExcel);

@Component({
  props: {
    date: {
      type: String,
    },
    date2: {
      type: String,
    },
    headers: {
      type: Array,
      required: true,
    },
    formDataVista: {
      type: Array,
      required: true,
    },
    jsonFields: {
      type: Object,
      required: true,
    },
    nombreReporte: {
      type: String,
      default: "Reporte",
    },
  },
  components: {
    reporte,
  },
  filters: {
    toMoney(value: number): string {
      if (typeof value !== "number") {
        return value;
      }
      return "$" + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
  },
})
export default class CajerosTable extends Vue {
  public IconClose: string = mdiClose;
  public IconExport: string = mdiCalendarExport;
  public cajerosArr = [] as Kiosko[];
  public clienteSP = (): ApiSistemaPagos => {
    const client = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.$store.getters.appMode));
    client.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
    return new ApiSistemaPagos(client);
  };

  public json_meta = [
    [
      {
        key: "charset",
        value: "utf-8",
      },
    ],
  ];

  public get rules(): Rules {
    return this.$store.state.validationRules;
  }

  public get reporteNombre(): string {
    return "reporteCajeros" + this.$props.date + "/" + this.$props.date2 + ".xls";
  }

  public getFechaIni(): string {
    return this.$props.date;
  }

  public getFechaFin(): string {
    return this.$props.date2;
  }

  public totalPagos(): number {
    return (this.$props.formDataVista as ResumenFormaPago[]).reduce((r, i) => r + Number(i.cantidadOperaciones), 0);
  }
  public totalImportes(): number {
    return (this.$props.formDataVista as ResumenFormaPago[]).reduce((r, i) => r + Number(i.totalImporte), 0);
  }
  public totalEf(): number {
    return (this.$props.formDataVista as ResumenFormaPago[]).reduce((r, i) => r + Number(i.totalEfectivo), 0);
  }
  public totalTa(): number {
    return (this.$props.formDataVista as ResumenFormaPago[]).reduce((r, i) => r + Number(i.totalTarjeta), 0);
  }
  public totalCh(): number {
    return (this.$props.formDataVista as ResumenFormaPago[]).reduce((r, i) => r + Number(i.totalCheques), 0);
  }
  public totalCo(): number {
    return (this.$props.formDataVista as ResumenFormaPago[]).reduce((r, i) => r + Number(i.totalComisiones), 0);
  }
  public totalCoDi(): number {
    return (this.$props.formDataVista as ResumenFormaPago[]).reduce((r, i) => r + Number(i.totalCoDi), 0);
  }
}
